:root {
  --section-width: 90%;
  --section-height: 68vh;
  --section-bg-color: #E7FBF1;

  --xl-heading: 3.4rem;
  --l-heading: 3rem;
  --main-heading: 2.2rem;
  --m-heading: 2.2rem;
  --ms-heading: 1.25rem;
  --s-heading: 1.125rem;
  --text: 1rem;

  --bp-tablet: 600px;
  --bp-laptop: 1024px;
  --bp-desktop: 1640px;

  --section-margin: 80px;
  --heading-margin: 50px;
  --heading-gap: 16px;

  --background-color: #fefefe;
  --primary-color: #1DBF73;
  --big-btn-color: #E94C4F;
  --primary-light-color: #68E8AC;
  --primary-dark-color: #18a663;
  --secondary-color: #f9ad3e;
  --secondary-light-color: #fbc76d;
  --secondary-dark-color: #d68a2d;
  --accent-color: #e94c4f;
  --accent-light-color: #f19092;
  --accent-dark-color: #e31f22;

  --text-color: #1C2A23;
  --text-light-color: #72767E;
  --text-base-color: white;
  --text-gray-color: #8E9491;

  --border-color: #DFDFDF;

  --gray-color: #9f9f9fdd;
  --light-gray-color: #d2d2d2dd;
  --blue-color: #1976d2;
  --blue-dark-color: #1168be;
  --green-dark-color: #85ce33;
  --green-color: #93c65a;
  --green-light: #c0e08e;
  --orange-color: #f38f3c;
  --yellow-color: #f8dfab;
  --shadow: #bdbdbd;
  --shadow-light: #e2e2e2;

  --s-radius: 6px;
  --m-radius: 8px;
  --l-radius: 24px;

  --nav-height: 70px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html {
  /* font-size: 62.5%; */
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  background: var(--background-color);
  color: var(--text-color);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

h1 {
  font-size: var(--l-heading);
  font-weight: 700;
}
h2 {
  font-size: var(--main-heading);
  font-weight: 700;
}
h3 {
  font-size: var(--m-heading);
  font-weight: 700;
}
h4 {
  font-size: var(--ms-heading);
  font-weight: 600;
}
h5 {
  font-size: var(--s-heading);
  font-weight: 600;
}
h6 {
  font-size: var(--xl-heading);
}
p,
li,
button,
input,
select,
article,
title,
header,
textarea,
a,
label,
span {
  font-size: var(--text);
  color: var(--text-light-color);
}
span,
a {
  color: var(--text-color);
  font-weight: 500;
}


button {
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.15s ease-in-out;
  touch-action: pinch-zoom;
  white-space: nowrap;
}
/* button:hover {
  background-color: var(--primary-dark-color);
} */
.secondary-btn {
  background-color: var(--secondary-color);
}
.secondary-btn:hover {
  background-color: var(--secondary-dark-color);
}
.outline-btn {
  color: var(--text-gray-color);
  background-color: transparent;
  border: 1px solid var(--text-gray-color);
  padding: 8px 10px;

  &:hover {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}
.icon {
  padding: 0;
  min-width: 30px;
  background-color: transparent;
  color: gray;
  transition: all 0.1s ease-in-out;
}
.icon:hover {
  transform: scale(1.1);
  background-color: transparent;
}
input,select {
  outline: none;
  border-radius: 50px;
  color: var(--text-color);
  padding: 6px 14px;
  border: 2px solid var(--border-color);
  height: 42px;
}
input:focus {
  border-color: var(--primary-color);
}
input::placeholder {
  color: var(--text-gray-color);
}
textarea {
  color: var(--text-color);
  border-color: var(--border-color);
}
input:focus,
select:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline-color: var(--primary-color);
}
input[type="checkbox"]{
  height: 18px;
  width: 18px !important;
}
/* input[type="checkbox"]:checked{} */
a {
  text-decoration: none;
  cursor: pointer;
}
select {
  color: var(--text-gray-color);
}
li {
  list-style: none;
  /* cursor: pointer; */
}

/* svg {
  transition: all 0.1s ease-in-out !important;
}
svg:hover {
  transform: scale(1.2);
} */
#mobile-error {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* @media (max-width: 600px) {
  :root {
    --section-margin: 50px;
    --heading-margin: 30px;
    --section-width: 95%;

    --xl-heading: 3rem;
    --l-heading: 2.2rem;
    --main-heading: 1.75rem;
    --m-heading: 1.375rem;
    --ms-heading: 1.125rem;
    --s-heading: 1rem;
    --text: 0.9rem;
  }
  button {
    padding: 8px 12px;
    min-width: 100px;
  }
  input,
  select,
  button {
    height: 42px;
  }
} */

@media (max-width: 1024px) {
  :root {
    /* --section-margin: 50px; */
    --heading-margin: 30px;
    --section-width: 90%;

    --xl-heading: 3.2rem;
    --l-heading: 2.8rem;
    --main-heading: 2rem;
    --m-heading: 1.9rem;
    --ms-heading: 1.3rem;
    --s-heading: 1rem;
    --text: 0.9rem;
  }
  button {
    padding: 8px 12px;
    /* min-width: 100px; */
  }
}

.green-btn{
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}